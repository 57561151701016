<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<h5 class="mb-4">
								<strong>Detalle de Proveedor</strong>
							</h5>
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<supplierDetail />
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
import supplierDetail from '@/components/suppliers-detail'

export default {
	name: 'suppliersDetail',
	components: {
		supplierDetail,
	},
	computed: {
		spinnerLoaderLabel() {
			return this.$store.state.suppliers.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.suppliers.spinnerLoader
		},
	},
}
</script>